import { LocalStorageKeys } from "@/core/types";
import { getItemFromLocalStorage } from "@/core/utils";
import type { RouteLocationNormalized } from "vue-router";
import { guestOnly } from "./guest-only";
import { requiresAuth } from "./requires-auth";

export const middlewares = (to: RouteLocationNormalized) => {
  const hasAccessToken = Boolean(
    getItemFromLocalStorage<string>(LocalStorageKeys.AUTH_TOKEN)
  );

  const redirectTo =
    requiresAuth(to, hasAccessToken) ?? guestOnly(to, hasAccessToken);

  if (redirectTo) {
    return redirectTo;
  }
};
