import PusherSDK, { Channel } from "pusher-js";
import type { ChannelAuthorizationCallback } from "pusher-js";
import { LocalStorageKeys } from "../types";
import { getItemFromLocalStorage } from "../utils";

const pusher = new PusherSDK(import.meta.env.VITE_PUSHER_KEY, {
  channelAuthorization: {
    endpoint: `${import.meta.env.VITE_APP_API_URL}/broadcasting/auth`,
    transport: "ajax",
    async customHandler(
      params: { socketId: string; channelName: string },
      callback: ChannelAuthorizationCallback
    ) {
      const { socketId, channelName } = params;

      const body = new URLSearchParams({
        socket_id: socketId,
        channel_name: channelName,
      });

      const bearerToken: string =
        getItemFromLocalStorage(LocalStorageKeys.AUTH_TOKEN) ?? "";

      const response = await fetch(
        `${import.meta.env.VITE_APP_API_URL}/broadcasting/auth`,
        {
          method: "post",
          body,
          headers: {
            Accept: "application/json",
            Authorization: bearerToken,
          },
        }
      );

      const data = await response.json();
      callback(null, {
        auth: data.auth,
      });
    },
  },
  cluster: import.meta.env.VITE_PUSHER_CLUSTER,
  forceTLS: import.meta.env.VITE_APP_ENV !== "development",
});

type ITabChannelEvent =
  | "tab.item-added"
  | "tabs.closed"
  | "tab.discount-adjusted"
  | "tab.discount-applied"
  | "tab.line-item-voided"
  | "tab.moved-to-table"
  | "tabs.payment-confirmed"
  | "tabs.payment-failed"
  | "tabs.payment-added"
  | "tabs.settled";

const getChannel = (name: string): Channel => {
  const channel = pusher.channels.channels?.[name];

  if (!channel) {
    throw new Error(
      `It is not subscribed to the pusher channel '${name}' yet.`
    );
  }

  return channel;
};

const isSubscribed = (channelName: string): boolean => {
  return Boolean(pusher.channels.channels?.[channelName]?.subscribed);
};

const channelNameTransformer = (name: string, isPrivate = false): string => {
  return isPrivate ? `private-${name}` : name;
};

export const subscribe = (channel: string): Promise<Channel> => {
  return new Promise((resolve, reject) => {
    const subscription = pusher.subscribe(channel);

    subscription.bind("pusher:subscription_succeeded", () => {
      resolve(subscription);
    });

    subscription.bind("pusher:subscription_error", (e: any) => {
      reject(e);
    });
  });
};

export const connector = (): PusherSDK => {
  return pusher;
};

export const listenFromTab = async (
  tabId: string,
  events: Array<ITabChannelEvent> | "*",
  callback: (payload: any) => any
): Promise<void> => {
  const channelName = channelNameTransformer(`tabs.${tabId}`, true);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.bind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.bind(event, callback);
  });
};

export const unlistenFromTab = async (
  tabId: string,
  events: Array<ITabChannelEvent> | "*",
  callback: (payload: any) => any | undefined
): Promise<void> => {
  const channelName = channelNameTransformer(`tabs.${tabId}`, true);

  if (!isSubscribed(channelName)) {
    return;
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.unbind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.unbind(event, callback);
  });
};

export const unsubscribeAllPrivate = (): void => {
  const allChannels = pusher.channels.channels ?? [];
  const privateChannelNames = Object.keys(allChannels).filter((key) => {
    return key.startsWith("private-");
  });

  privateChannelNames.forEach((channelName) => {
    const channel = getChannel(channelName);
    channel.unbind();
    channel.unsubscribe();
  });
};
