import type {
  IRepoFetchAutomaticDiscountContract,
  IRepoFetchAutomaticDiscountRequest,
  IRepoFetchAutomaticDiscountResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchAutomaticDiscount";

export async function repoFetchAutomaticDiscount(
  request: IRepoFetchAutomaticDiscountRequest
): Promise<IRepoFetchAutomaticDiscountResponse | undefined> {
  let response: IRepoFetchAutomaticDiscountContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/businesses/${request.businessId}/vouchers`,
      {
        searchParams: {
          outletId: request.outletId,
          orderingService: "dine-in",
        },
      }
    );

    response = await rawResponse.json();

    const validDiscount = response.data.find(
      (discount) =>
        discount.discounted_items.length === 0 &&
        discount.max_usage === 0 &&
        discount.max_uses_per_customer === 0 &&
        discount.minimum_order_value === 0 &&
        discount.auto_checkout &&
        discount.segmentation === "all" &&
        discount.visibility === "automatic"
    );

    if (!validDiscount) {
      return undefined;
    }

    return {
      id: validDiscount.id,
      code: validDiscount.code,
      amount: validDiscount.amount,
      unit: validDiscount.unit === "percentage" ? "percentage" : "fixed",
      maxDiscount:
        validDiscount.maximum_discount > 0
          ? validDiscount.maximum_discount
          : undefined,
    };
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
