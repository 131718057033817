import type {
  IRepoFetchOpenTabFromSpotContract,
  IRepoFetchOpenTabFromSpotRequest,
  IRepoFetchOpenTabFromSpotResponse,
  IRepoFetchOpenTabFromSpotQuery,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchOpenTabFromSpot";

export async function repoFetchOpenTabFromSpot(
  request: IRepoFetchOpenTabFromSpotRequest
): Promise<IRepoFetchOpenTabFromSpotResponse | undefined> {
  let response: IRepoFetchOpenTabFromSpotContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const searchParams: IRepoFetchOpenTabFromSpotQuery = {
      table_id: request.spotId,
      state: "OPEN",
      per_page: 10,
      sort: "-created_at",
    };

    const rawResponse = await http().get(`tabs`, { searchParams });
    response = await rawResponse.json();

    const tab = response.data[0];
    if (!tab) {
      return undefined;
    }

    return {
      id: tab.id,
      owner: {
        name: tab.owner.name,
        phoneNumber: tab.owner.phone_number,
      },
      joiners: tab.joiners.map((joiner) => ({
        name: joiner.name,
        phoneNumber: joiner.phone_number,
      })),
    };
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
