import { RouteNameEnum } from "@/router/route-name.enum";
import type { RouteLocationNormalized } from "vue-router";

export const requiresAuth = (
  to: RouteLocationNormalized,
  hasAccessToken: boolean
) => {
  if (to.meta.requiresAuth && !hasAccessToken) {
    return {
      name: RouteNameEnum.AUTH,
      params: to.params,
      query: {
        redirect: to.fullPath,
      },
    };
  }
};
