import mixpanel from "mixpanel-browser";
import type { trackingRegister } from "./register";

const mixPanelActive = import.meta.env.VITE_APP_MIXPANEL_ENABLED === "true";

export function initMixpanel() {
  if (!mixPanelActive) return;

  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_TOKEN, {
    debug: import.meta.env.VITE_APP_MIXPANEL_DEBUG === "true" || false,
  });

  mixpanel.register({
    product: "OAT 2.0",
    orderingService: "dine-in",
    channel: "chatfood",
  });
}

export function tagMixpanel(params: {
  businessId?: string;
  businessName?: string;
  outletId?: string;
  outletName?: string;
  tableNumber?: string;
}) {
  if (!mixPanelActive) return;

  const props = {
    business_id: params.businessId,
    business_name: params.businessName,
    outlet_id: params.outletId,
    outlet_name: params.outletName,
    table_number: params.tableNumber,
  };

  const sanitizedProps = Object.entries(props)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, v]) => v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  mixpanel.register(sanitizedProps);
}

export function mixpanelIdentify(userId: string) {
  if (!mixPanelActive) return;

  mixpanel.alias(userId);
  mixpanel.identify(userId);
}

// The "countryCode" here is actually the calling code not the country code :)
export function mixpanelSetPerson(params: {
  id: string;
  name?: string;
  email?: string;
  countryCode: string;
  phoneNumber: string;
}) {
  if (!mixPanelActive) return;
  mixpanel.people.set({
    ID: params.id,
    $name: params.name,
    $email: params.email,
    $phone: `+${params.countryCode}${params.phoneNumber}`,
    "Country Code": params.countryCode,
    "Phone Number": params.phoneNumber,
  });
}

export function mixpanelTrack<TName extends keyof trackingRegister>(
  eventName: TName,
  requiredData: trackingRegister[TName]
) {
  if (!mixPanelActive) return;
  mixpanel.track(eventName, requiredData || {});
}
