import {
  useBusinessStore,
  useOutletStore,
  useSpotStore,
  useLoyaltyProgramStore,
  useBasketStore,
  useUserStore,
} from "@/stores";
import { appRepoFetchBusiness } from "@/layouts/app/repo/fetch-business";
import { appRepoFetchLoyaltyProgram } from "@/layouts/app/repo/fetch-loyalty-program";
import { appRepoFetchOutlet } from "@/layouts/app/repo/fetch-outlet";
import { appRepoFetchSpot } from "@/layouts/app/repo/fetch-spot";
import { appRepoFetchColorset } from "@/layouts/app/repo/fetch-colorset";
import { appRepoFetchGatewayConfig } from "@/layouts/app/repo/fetch-gateway-config";
import {
  repoFetchAutomaticDiscount,
  type IRepoFetchAutomaticDiscountResponse,
} from "@/repos/fetch-automatic-discount";
import { setCurrentLanguage } from "@/i18n";
import {
  tagMixpanel,
  mixpanelIdentify,
  mixpanelSetPerson,
} from "@/core/tracking";
import { appRepoFetchServiceCharge } from "@/layouts/app/repo/fetch-service-charge";
import {
  report,
  reportGlobalTags,
  reportIdentify,
} from "@chatfood/bug-reporter";
import type { IErrorViewProps } from "@/views/error";
import { useOrderStore } from "@/modules/order/store";
import { useCustomerStore } from "@/stores/customer";
import { repoFetchOutletPaymentMethods } from "@/repos/fetch-outlet-payment-methods";
import { repoFetchTabOutletSettings } from "@/repos/fetch-tab-outlet-settings";
import { defineColorset } from "@/core/utils";
import type { IDomainCurrency } from "@/domain/currency";

type IBootstrapAppError = {
  status: IErrorViewProps["status"];
  message?: string;
};

export function useBootstrap() {
  // store
  const spot = useSpotStore();
  const outlet = useOutletStore();
  const business = useBusinessStore();
  const customer = useCustomerStore();
  const user = useUserStore();
  const basket = useBasketStore();
  const loyaltyProgram = useLoyaltyProgramStore();
  const order = useOrderStore();

  const bootstrapSpot = async (spotId: string, isTabEnabled: boolean) => {
    const res = await appRepoFetchSpot({ spotId });

    reportGlobalTags({
      "spot.id": res.id,
      "spot.name": res.name,
    });

    spot.setSpot(res);

    tagMixpanel({
      tableNumber: res.name,
    });

    if (isTabEnabled) {
      await spot.syncOpenTab();
    }

    return {
      outletId: res.outletId,
    };
  };

  const bootstrapOutlet = async (outletSlug: string) => {
    const res = await appRepoFetchOutlet({ outletSlug });

    reportGlobalTags({
      "outlet.id": res.id,
      "outlet.slug": res.slug,
    });

    tagMixpanel({
      outletId: res.id,
      outletName: res.name,
    });

    outlet.setOutlet(res);

    const paymentMethods = await repoFetchOutletPaymentMethods({
      outletId: res.id,
    });

    outlet.setPaymentMethods(paymentMethods.filter((p) => p !== "applePay"));

    if (outlet.hasAddToTab) {
      outletTabSettings(res.id);
    }

    return {
      businessId: res.businessId,
      hasApplePay: paymentMethods.find((p) => p === "applePay"),
    };
  };

  const outletTabSettings = async (outletId: string) => {
    const tabSettings = await repoFetchTabOutletSettings({
      outletId,
    });
    outlet.setTabSettings({
      isCardRequired: tabSettings.cardRequiredToStartTab,
    });
  };

  const bootstrapBusiness = async (businessId: string) => {
    const [biz, gatewayConfig, colorset] = await Promise.all([
      appRepoFetchBusiness({ businessId }),
      appRepoFetchGatewayConfig({ businessId }),
      appRepoFetchColorset({ businessId }),
    ]);

    reportGlobalTags({
      "business.id": biz.id,
      "business.slug": biz.slug,
    });

    tagMixpanel({
      businessId: biz.id,
      businessName: biz.name,
    });

    business.setBusiness({
      ...biz,
      gatewayConfig,
    });

    setCurrentLanguage(biz.locale);

    if (colorset) {
      defineColorset(colorset);
    }
  };

  const bootstrapCustomer = async (
    businessId: string,
    currency: IDomainCurrency
  ) => {
    await customer.syncState(businessId, currency);
    reportIdentify({ id: customer.id, email: customer.email });
    mixpanelIdentify(customer.id);
    mixpanelSetPerson({
      id: customer.id,
      countryCode: customer.countryCode,
      phoneNumber: customer.phoneNumber,
      email: customer.email,
      name: customer.name,
    });
  };

  const bootstrapOrder = (outletSlug: string) => {
    order.startPersistence(`recent.${outletSlug}`);
  };

  const bootstrapLoyaltyProgram = async (businessId: string) => {
    const res = await appRepoFetchLoyaltyProgram({ businessId });

    if (res) {
      loyaltyProgram.setLoyaltyProgram(res);
    }
  };

  const bootstrapUser = async () => {
    user.resetLoggedIn();
  };

  const bootstrapBasket = async (
    outletSlug: string,
    outletId: string,
    businessId: string,
    currency: IDomainCurrency
  ) => {
    basket.startPersistence(outletSlug);
    basket.setCurrency(currency);

    if (!basket.discount) {
      handleAutomaticDiscount(outletId, businessId);
    }

    const serviceCharge = await appRepoFetchServiceCharge({ outletId });
    basket.setServiceChargePercentage(serviceCharge);
  };

  const handleAutomaticDiscount = async (
    outletId: string,
    businessId: string
  ) => {
    let discount: undefined | IRepoFetchAutomaticDiscountResponse;

    try {
      discount = await repoFetchAutomaticDiscount({
        businessId,
        outletId,
      });
    } catch (error) {
      report(error);
    }

    if (discount) {
      basket.applyAutomaticDiscount(discount);
    }
  };

  const handleApplePayAvailability = async () => {
    const applePaySession = (window as any).ApplePaySession;
    if (!applePaySession) return;

    const hasCardOnWallet = await applePaySession.canMakePaymentsWithActiveCard(
      import.meta.env.VITE_APP_APPLE_PAY_MERCHANT_IDENTITY_CERTIFICATE
    );

    if (!hasCardOnWallet) return;

    outlet.addPaymentMethod("applePay");
  };

  const handle = async (outletSlug: string, spotId: string) => {
    await handleWithoutSpot(outletSlug);
    const res = await bootstrapSpot(spotId, outlet.hasAddToTab);

    if (res.outletId !== outlet.id) {
      const error: IBootstrapAppError = {
        status: "spot-not-found",
      };
      throw error;
    }
  };

  const handleWithoutSpot = async (outletSlug: string) => {
    await bootstrapUser();

    const { businessId, hasApplePay } = await bootstrapOutlet(outletSlug);
    await bootstrapBusiness(businessId);

    if (hasApplePay && business.gatewayConfig.isApplePayEnabled) {
      handleApplePayAvailability();
    }

    await Promise.all([
      user.isLoggedIn
        ? bootstrapCustomer(business.id, business.currency)
        : null,
      bootstrapLoyaltyProgram(business.id),
      bootstrapBasket(outlet.slug, outlet.id, business.id, business.currency),
    ]);

    bootstrapOrder(outletSlug);
  };

  return {
    handle,
    handleWithoutSpot,
    bootstrapSpot,
  };
}
