import type {
  IAppRepoFetchMenusContract,
  IAppRepoFetchMenusRequest,
  IAppRepoFetchMenusResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "appRepoFetchMenus";

export async function appRepoFetchMenus(
  request: IAppRepoFetchMenusRequest
): Promise<IAppRepoFetchMenusResponse> {
  let response: IAppRepoFetchMenusContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http({ cacheTTL: 60 }).get(
      `storefront/businesses/${request.businessId}/outlets/${request.outletId}/menus`,
      {
        searchParams: { ordering_service: "dine-in" },
      }
    );

    response = await rawResponse.json();

    return response.data.map((menu) => ({
      id: menu.id,
      name: menu.name,
      slug: menu.slug,
      summary: menu.summary ?? undefined,
      icon: menu.icon,
      viewOnly: menu.is_view_only,
    }));
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
