import { defineStore } from "pinia";
import type {
  IBasketDiscount,
  IBasketItem,
  IBasketLineItem,
  IBasketModifier,
  IBasketStore,
} from "./types";
import { calculateTotalPrice, generateLineId } from "./utils";
import type { IPrice } from "@/core/types";
import { ExpireInMinutesEnum } from "./../plugins/persisted-state";

function reduceQuantity(total: number, lineItem: IBasketLineItem) {
  return total + lineItem.quantity;
}

function reduceTotal(total: number, lineItem: IBasketLineItem) {
  return total + lineItem.totalPrice.value * lineItem.quantity;
}

export const basketId = "basket";

export class BasketStoreError extends Error {
  public readonly cause?: Error;

  constructor(message: string, cause?: Error) {
    super(message);
    this.name = "BasketStoreError";
    this.cause = cause;
  }
}

export const useBasketStore = defineStore(basketId, {
  persist: true,
  expireInMinutes: ExpireInMinutesEnum.DAY,
  state: (): IBasketStore => {
    return {
      redeemLoyaltyPoints: true,
      tipPercentage: undefined,
      tipAmount: undefined,
      chosenPaymentKey: undefined,
      currency: "AED",
      serviceChargePercentage: 0,
      selectedPaymentMethodId: "",
      defaultPaymentMethodId: "",
      discount: undefined,
      lines: [],
    };
  },
  getters: {
    /**
     * The total price of only the items in the basket
     * without any other calculation applied
     */
    totalPrice(): IPrice {
      return {
        value: this.lines.reduce(reduceTotal, 0),
        currency: this.currency,
      };
    },
    /**
     * The total tips after the percentage calculation
     * has been applied
     */
    totalTips(): IPrice {
      return {
        value: this.tipPercentage
          ? Math.ceil(this.totalPrice.value * (this.tipPercentage / 100))
          : 0,
        currency: this.currency,
      };
    },
    /**
     * The total number of line entries in the basket
     */
    linesCount(): number {
      return this.lines.length;
    },
    /**
     * The total quantity of items in a basket regardless
     * of the number of lines they span across
     */
    totalQuantity(): number {
      return this.lines.reduce(reduceQuantity, 0);
    },
    getBasketLines(): IBasketStore["lines"] {
      return this.lines;
    },
  },
  actions: {
    resetBasket(): void {
      this.resetBasketDiscount();
      this.resetBasketTips();
      this.resetBasketLines();
    },

    resetBasketTips(): void {
      this.$patch({
        tipPercentage: undefined,
      });
    },

    resetBasketDiscount(): void {
      this.$patch({
        discount: undefined,
      });
    },

    resetBasketLines(): void {
      this.$patch({
        lines: [],
      });
    },

    setCurrency(currency: string): void {
      this.currency = currency;
    },

    setServiceChargePercentage(percentage: number): void {
      this.serviceChargePercentage = percentage;
    },

    applyDiscount(discount: IBasketDiscount): void {
      this.redeemLoyaltyPoints = false;
      this.discount = discount;
    },

    applyAutomaticDiscount(discount: IBasketDiscount): void {
      this.applyDiscount(discount);
    },

    removeDiscount(): void {
      this.discount = undefined;
    },

    spendLoyaltyPoints(): void {
      this.discount = undefined;
      this.redeemLoyaltyPoints = true;
    },

    setSelectedPaymentMethodId(id: string): void {
      this.selectedPaymentMethodId = id;
    },

    preserveLoyaltyPoints(): void {
      this.redeemLoyaltyPoints = false;
    },

    setTipPercentage(percent: number | undefined): void {
      this.tipPercentage = percent;
    },

    setTipAmount(amount: number): void {
      this.tipAmount = amount;
    },

    setChosenPaymentKey(key: IBasketStore["chosenPaymentKey"]): void {
      this.chosenPaymentKey = key;
    },

    addLineItem(
      item: IBasketItem,
      quantity: number,
      modifiers: Array<IBasketModifier> = []
    ): void {
      const newLineId = generateLineId(item.id, item.price.value, modifiers);
      const lineItem = this.findItemByLineId(newLineId);

      if (!lineItem) {
        this.lines.push({
          lineId: newLineId,
          id: item.id,
          name: item.name,
          totalPrice: calculateTotalPrice(item.price, modifiers),
          price: item.price,
          note: item.note || "",
          modifiers,
          quantity,
        });
        return;
      }

      lineItem.quantity += quantity;
      lineItem.totalPrice = calculateTotalPrice(
        lineItem.price,
        lineItem.modifiers
      );
    },

    increaseItemByLineId(lineId: string): IBasketStore["lines"][0] {
      const lineItem = this.findItemByLineId(lineId);

      if (!lineItem) {
        throw new BasketStoreError("Trying to increase an invalid line item");
      }

      lineItem.quantity++;
      lineItem.totalPrice = calculateTotalPrice(
        lineItem.price,
        lineItem.modifiers
      );

      return lineItem;
    },

    /**
     * @deprecated use `increaseItemByLineId` instead
     * @todo remove once checkout overhaul is done
     */
    increaseLineItem(lineId: string): void {
      const lineItem = this.findItemByLineId(lineId);
      if (lineItem) {
        lineItem.quantity++;
        lineItem.totalPrice = calculateTotalPrice(
          lineItem.price,
          lineItem.modifiers
        );
      }
    },

    decreaseItemByLineId(lineId: string): IBasketStore["lines"][0] {
      const lineItem = this.findItemByLineId(lineId);

      if (!lineItem) {
        throw new BasketStoreError("Trying to decrease an invalid line item");
      }

      lineItem.quantity--;
      lineItem.totalPrice = calculateTotalPrice(
        lineItem.price,
        lineItem.modifiers
      );

      if (lineItem.quantity === 0) {
        this.removeLineItem(lineItem.lineId);
      }

      return lineItem;
    },

    /**
     * @deprecated use `decreaseItemByLineId` instead
     * @todo remove once checkout overhaul is done
     */
    decreaseLineItem(lineId: string): void {
      const lineItem = this.findItemByLineId(lineId);

      if (lineItem) {
        if (lineItem.quantity > 1) {
          lineItem.quantity--;
          lineItem.totalPrice = calculateTotalPrice(
            lineItem.price,
            lineItem.modifiers
          );
          return;
        }
        this.removeLineItem(lineItem.lineId);
      }
    },

    removeLineItem(lineId: string): void {
      const index = this.lines.findIndex(
        (lineItem) => lineItem.lineId === lineId
      );

      if (index > -1) {
        this.lines.splice(index, 1);
      }
    },

    getMenuItemQuantity(id: string): number {
      return this.lines
        .filter((lineItem) => lineItem.id === id)
        .reduce(reduceQuantity, 0);
    },

    findLastBasketLineItem(itemId: string) {
      return this.lines.filter((line) => line.id === itemId).pop();
    },

    findItemByLineId(lineId: string): IBasketLineItem | undefined {
      return this.lines.find((line) => line.lineId === lineId);
    },
  },
});
