import type {
  IAppRepoFetchOutletContract,
  IAppRepoFetchOutletRequest,
  IAppRepoFetchOutletResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import {
  http,
  repoBuildAndReportError,
  RepoKnownError,
} from "@/core/repo-utils";

const repoName = "appRepoFetchOutlet";

export async function appRepoFetchOutlet(
  request: IAppRepoFetchOutletRequest
): Promise<IAppRepoFetchOutletResponse> {
  let response: IAppRepoFetchOutletContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const ONE_HOUR = 60 * 60;
    const rawResponse = await http({ cacheTTL: ONE_HOUR }).get(
      `storefront/outlet-by-slug/${request.outletSlug}`
    );

    response = await rawResponse.json();

    return {
      id: response.id,
      slug: request.outletSlug,
      businessId: response.business_id,
      name: response.name,
    };
  } catch (err) {
    if (err instanceof HTTPError && err.response.status == 404) {
      throw new RepoKnownError(
        repoName,
        "outlet_not_found",
        "The outlet slug provided is not valid."
      );
    }

    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
