import { toMoney } from "@chatfood/core-utils";
import dayjs from "dayjs";
import type { IPrice } from "./types";
import type { IAppRepoFetchColorsetResponse } from "@/layouts/app/repo/fetch-colorset";
import { colorSetter } from "@chatfood/design-system";

export function saveItemInLocalStorage(itemKey: string, item: any): void {
  if (!item) return;
  localStorage.setItem(itemKey, JSON.stringify(item));
}

export function getItemFromLocalStorage<T>(itemKey: string): T | null {
  const item = localStorage.getItem(itemKey);
  if (!item) return null;
  let parsedItem: any;
  try {
    parsedItem = JSON.parse(item) as T;
  } catch (e) {
    parsedItem = item;
  }
  return parsedItem;
}

export function removeItemFromLocalStorage(itemKey: string): void {
  localStorage.removeItem(itemKey);
}

export function getCurrencyWithoutSymbol(price: IPrice) {
  return Number(
    toMoney(price.value, price.currency, {
      hideSymbol: true,
    })
  );
}

export function applyPercentageToAmount(
  amount: number,
  percentage: number
): number {
  return amount * (percentage / 100);
}

type IFormat = "datetime" | "time";

export const dateFormat = (date: Date | string, format?: IFormat): string => {
  const formats: Record<IFormat, string> = {
    datetime: "D MMM YYYY [at] H:mm",
    time: "h:mma",
  };

  const dateInstance = dayjs(date);

  if (!dateInstance.isValid()) {
    throw new Error("dateFormat: invalid date given");
  }

  return dateInstance.format(format ? formats[format] : formats["datetime"]);
};

export const defineColorset = (colorset: IAppRepoFetchColorsetResponse) => {
  if (colorset.primary) {
    colorSetter("primary", colorset.primary);
  }

  if (colorset.onPrimary) {
    colorSetter("onPrimary", colorset.onPrimary);
  }

  if (colorset.primaryHover) {
    colorSetter("primaryHover", colorset.primaryHover);
  }

  if (colorset.secondary) {
    colorSetter("secondary", colorset.secondary);
  }

  if (colorset.onSecondary) {
    colorSetter("onSecondary", colorset.onSecondary);
  }

  if (colorset.secondaryHover) {
    colorSetter("secondaryHover", colorset.secondaryHover);
  }
};
