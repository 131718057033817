import { LocalStorageKeys } from "@/core/types";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "@/core/utils";
import { defineStore } from "pinia";
import type { IUserStore } from "./types";

export const storeName = "user";

export const useUserStore = defineStore(storeName, {
  state: (): IUserStore => ({
    isLoggedIn: Boolean(
      getItemFromLocalStorage<string>(LocalStorageKeys.AUTH_TOKEN)
    ),
  }),
  actions: {
    markAsLoggedIn() {
      this.isLoggedIn = true;
    },
    markAsLoggedOut() {
      this.isLoggedIn = false;
    },
    resetLoggedIn() {
      const token = getItemFromLocalStorage<string>(
        LocalStorageKeys.AUTH_TOKEN
      );
      token ? this.markAsLoggedIn() : this.markAsLoggedOut();
    },
    logout() {
      this.markAsLoggedOut();
      removeItemFromLocalStorage(LocalStorageKeys.AUTH_TOKEN);
    },
  },
});
