import type {
  IAppRepoFetchSpotContract,
  IAppRepoFetchSpotRequest,
  IAppRepoFetchSpotResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import {
  http,
  repoBuildAndReportError,
  RepoKnownError,
} from "@/core/repo-utils";

const repoName = "appRepoFetchSpot";

export async function appRepoFetchSpot(
  request: IAppRepoFetchSpotRequest
): Promise<IAppRepoFetchSpotResponse> {
  let response: IAppRepoFetchSpotContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(`pat/tables/${request.spotId}`);

    response = await rawResponse.json();

    return {
      id: response.table_id,
      name: response.table_name,
      outletId: response.location.id,
    };
  } catch (err) {
    if (err instanceof HTTPError && err.response.status == 404) {
      throw new RepoKnownError(
        repoName,
        "spot_not_found",
        "The spot provided is not valid."
      );
    }

    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
