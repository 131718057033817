type IRepoKnownErrorCode =
  | "business_not_found"
  | "credit_card_unauthorized"
  | "card_verification_unsuccessful"
  | "item_out_of_stock"
  | "no_outstanding_balance"
  | "order_has_no_online_payment"
  | "order_with_no_points_earned"
  | "outlet_closed"
  | "outlet_is_not_accepting_orders"
  | "outlet_not_found"
  | "owner_cannot_join_own_spot"
  | "phone_verification_code_doesnt_match"
  | "request_otp_too_many_attempts"
  | "selected_payment_method_unavailable"
  | "spot_not_found"
  | "tab_not_found"
  | "tab_payment_not_found"
  | "validate_otp_too_many_attempts";

/**
 * Intended to handle cases where an error
 * is expected as a result of a request
 */
export class RepoKnownError extends Error {
  public code: IRepoKnownErrorCode;

  constructor(
    repoName: string,
    errorCode: IRepoKnownErrorCode,
    message: string
  ) {
    super(message);
    this.name = repoName;
    this.code = errorCode;
  }
}

export const isKnownError = (
  exception: unknown,
  errorCode: IRepoKnownErrorCode
) => {
  return exception instanceof RepoKnownError && exception.code === errorCode;
};
