import { appRepoFetchMenus } from "@/layouts/app/repo/fetch-menus";
import type { IAppRepoFetchOutletResponse } from "@/layouts/app/repo/fetch-outlet";
import { appRepoFetchOutlet } from "@/layouts/app/repo/fetch-outlet";
import { RouteNameEnum } from "@/router/route-name.enum";
import { useUiStore } from "@/stores";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

const toSingleMenu = (to: RouteLocationNormalized): RouteLocationRaw => ({
  name: RouteNameEnum.SINGLE_MENU,
  params: to.params,
  query: to.query,
  replace: true,
});

export const multiMenuOnly = async (
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | undefined> => {
  const uiStore = useUiStore();

  if (uiStore.isSingleMenu === false) {
    return undefined;
  }

  if (uiStore.isSingleMenu === true) {
    return toSingleMenu(to);
  }

  const paramOutletSlug = to.params.outletSlug;
  const outletSlug = Array.isArray(paramOutletSlug)
    ? paramOutletSlug[0]
    : paramOutletSlug;

  let outlet: IAppRepoFetchOutletResponse;
  try {
    outlet = await appRepoFetchOutlet({ outletSlug });
  } catch (error) {
    uiStore.markViewAsError("outlet-not-found");
    throw error;
  }

  const [menus] = await Promise.all([
    appRepoFetchMenus({ businessId: outlet.businessId, outletId: outlet.id }),
  ]);

  if (menus.length > 1) {
    uiStore.markAsMultiMenu();
    return undefined;
  }

  uiStore.markAsSingleMenu();
  return toSingleMenu(to);
};
