import type { IDomainPaymentMethod } from "@/domain/payment-method";
import { defineStore } from "pinia";
import type { IOutletStore } from "./types";

export const outletId = "outlet";

export const useOutletStore = defineStore(outletId, {
  state: (): IOutletStore => ({
    id: "",
    slug: "",
    name: "",
    paymentMethods: [],
    tabSettings: {
      isCardRequired: false,
    },
  }),
  getters: {
    hasAddToTab(): boolean {
      return this.paymentMethods.includes("addToTab");
    },
    hasApplePay(): boolean {
      return this.paymentMethods.includes("applePay");
    },
    hasSkipCash(): boolean {
      return this.paymentMethods.includes("skipcash");
    },
    hasCardMachine(): boolean {
      return this.paymentMethods.includes("cardMachine");
    },
    hasCardOnline(): boolean {
      return this.paymentMethods.includes("cardOnline");
    },
    hasCash(): boolean {
      return this.paymentMethods.includes("cash");
    },
    requiresCardToStartTab(): boolean {
      return this.tabSettings.isCardRequired;
    },
  },
  actions: {
    setOutlet(newState: Omit<IOutletStore, "paymentMethods" | "tabSettings">) {
      this.id = newState.id;
      this.slug = newState.slug;
      this.name = newState.name;
    },
    setTabSettings(newState: IOutletStore["tabSettings"]) {
      this.tabSettings = newState;
    },
    setPaymentMethods(options: IDomainPaymentMethod[]) {
      this.paymentMethods = options;
    },
    addPaymentMethod(option: IDomainPaymentMethod) {
      this.paymentMethods.push(option);
    },
  },
});
