import type {
  IAppRepoFetchLoyaltyProgramContract,
  IAppRepoFetchLoyaltyProgramRequest,
  IAppRepoFetchLoyaltyProgramResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";
import camelcaseKeys from "camelcase-keys";

const repoName = "appRepoFetchLoyaltyProgram";

export async function appRepoFetchLoyaltyProgram(
  request: IAppRepoFetchLoyaltyProgramRequest
): Promise<IAppRepoFetchLoyaltyProgramResponse | undefined> {
  let response: IAppRepoFetchLoyaltyProgramContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/reward-program-for-business/${request.businessId}`
    );

    response = await rawResponse.json();

    return {
      ...camelcaseKeys(response),
      signupPoints: response.signup_points ?? undefined,
    };
  } catch (err) {
    if (err instanceof HTTPError && err.response.status === 404) {
      return undefined;
    }

    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
